<template>
  <div class="loginRegister">
    <div class="animation" ref="animation">
      <div class="login_logo">
        <img src="@/assets/img/login/aishipgo.png" style="" alt="logo" />
      </div>
      <div class="login_title">
        提供跨境业务AI能力及解决方案，综合运用NLP、CV、多模态、
        ML等技术，解决社媒引流、站内营销、风控安全等业务问题。
        让跨境生意更简单、更高效。
      </div>
      <div
        class="imgarr"
        ref="imgarr"
        @mouseenter="clear()"
        @mouseleave="mouse()"
      >
        <div class="imgDiv" ref="imgDiv">
          <ScrollBg />
        </div>
      </div>
    </div>
    <div class="login_div">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ScrollBg from './components/scrollBg.vue';

import '../../assets/css/customElement.less';
export default {
  name: '',
  components: {
    ScrollBg,
  },
  data() {
    return {
      time: '',
      is: true,
    };
  },
  mounted() {
    this.set();
  },

  methods: {
    move() {
      // 父元素位置
      const imgarr = this.$refs.imgarr;
      const imgDiv = this.$refs.imgDiv;
      if (imgDiv.offsetLeft <= 0 && !this.is) {
        imgDiv.style.left = imgDiv.offsetLeft + 50 + 'px';
      } else {
        this.is = true;
      }
      if (
        -imgDiv.offsetLeft + imgarr.clientWidth <= imgDiv.scrollWidth &&
        this.is
      ) {
        imgDiv.style.left = imgDiv.offsetLeft - 50 + 'px';
      } else {
        this.is = false;
      }
    },
    set() {
      // this.time = setInterval((e) => {
      //   this.move();
      // }, 500);
    },
    clear() {
      clearInterval(this.time);
    },
    mouse() {
      this.set();
    },
  },
  beforeDestroy() {
    console.log('我要被挂载了');
    this.clear();
  },
};
</script>

<style lang="less" scoped>
.loginRegister {
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  .animation {
    max-width: 50%;
    flex: 1;
    height: 100%;
    background-image: url('../../assets/img/login/image.png');
    background-size: 100% 100%;
    .login_logo {
      margin-top: 122px;
      margin-left: 80px;
      display: flex;
      img {
        width: 401px;
      }
      div {
        margin-top: -15 px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .login_title {
      width: 560px;
      margin-top: 24px;
      margin-left: 80px;

      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0px;

      color: #ffffff;
    }
    .imgarr {
      position: relative;
      width: 100%;
      height: 327px;
      overflow: hidden;
      display: flex;
      margin-top: 120px;
      .imgDiv {
        width: 100%;
        position: relative;
        transition: all 1s linear;
        img {
          // width: 100%;
          height: 327px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .login_div {
    width: 50%;
    min-width: 720px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1280px) {
  .loginRegister {
    .animation {
      .login_logo {
        padding-right: 20px;
        margin-top: 122px;
        margin-left: 50px;
        display: flex;
        img {
          width: 280px;
        }
        div {
          margin-top: -15 px;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
        }
      }
      .login_title {
        width: 400px;
        margin-top: 24px;
        margin-left: 60px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0px;

        color: #ffffff;
      }
    }
    .login_div {
      width: 50%;
      min-width: 576px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: 990px) {
  .loginRegister {
    .animation {
      .login_logo {
        margin-top: 122px;
        margin-left: 40px;
        display: flex;
        img {
          width: 180px;
        }
        div {
          margin-top: -15 px;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
        }
      }
      .login_title {
        width: 320px;
        margin-top: 24px;
        margin-left: 40px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0px;

        color: #ffffff;
      }
    }
    .login_div {
      width: 50%;
      min-width: 468px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
