<!--
 * @Date: 2024-01-19 16:28:55
 * @LastEditTime: 2024-01-22 18:08:02
 * @Description: file content
-->
<template>
  <div>
    <div id="bg" ref="bg" :style="bgStyle" class="animation"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgStyle: {
        width: null,
        '--x-offset': null,
      },
      // 图片的尺寸
      image: {
        width: 650,
        height: 327,
      },
    };
  },
  mounted() {
    const fun = () => {
      const clientHeight =
        document.body.clientHeight |
        (document.documentElement && document.documentElement.clientHeight);
      const xOffset = parseInt(
        (this.image.width / this.image.height) * clientHeight,
      );
      this.bgStyle['--x-offset'] = xOffset + 'px';
      this.bgStyle.width = document.body.clientWidth + xOffset + 'px';
    };
    window.onresize = () => {
      fun();
      // 重置 animation 保证动画刷新
      this.$refs.bg.classList.remove('animation');
      setTimeout(() => {
        this.$refs.bg.classList.add('animation');
      }, 100);
    };
    fun();
  },
};
</script>

<style lang="less">
/* 需要污染到全局的 css ，防止带上 hash 影响操作 */

@keyframes bg {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-1 * var(--x-offset)));
  }
}

.animation {
  animation: bg 30s linear infinite reverse;
}
</style>

<style scoped lang="less">
/* 背景图像的节点 */

#bg {
  background: url('../../../assets/img/login/imgs.png') top left;
  background-repeat: repeat-x;
  background-size: var(--x-offset) auto, auto;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
